<template>
    <div>
        <b-card no-body class="mb-2 p-1 d-print-none">
            <b-row>
                <b-col md="12" class="text-right">
                    <b-button @click="print" variant="warning" class="mr-1" title="Печать в формате PDF">
                        <b-icon-printer-fill scale="1.2"/>
                    </b-button>
                    <b-button @click="filter" variant="primary" class="mr-1" title="Обновить данные">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                </b-col>
            </b-row>

            <b-collapse id="filters">
                <b-card-body>
                    <b-row>
                        <b-col md="4">
                            <b-form-group description="План на дату">
                                <b-form-datepicker
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder=""
                                    v-model="filters.date"
                                    @input="filter"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group description="Цех">
                                <storage-picker v-model="filters.storage" @input="filter" :allowedOnly="true"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group description="Смена">
                                <shift-input v-model="filters.shift" @input="filter"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="3">
                            <b-form-group>
                                <b-checkbox v-model="filters.heat" @input="filter">Термообработка</b-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </b-card>

        <h1 class="text-center">Сменные задания по планам</h1>
        <h3 class="text-center">
            <template v-if="filters.storage && filters.shift">
                {{ filters.storage.name }} ({{ date }} / {{ filters.shift.name }})
            </template>
        </h3>

        <b-card
            v-for="planTasks in groupedTasks"
            border-variant="dark"
            header-border-variant="dark"
            class="mt-2 page"
        >
            <div class="card-header font-weight-bold">
                <h3>
                    <b-row class="d-print-none">
                        <b-col md="8">{{ planTasks[0].plan.nomenclature.name }}</b-col>
                        <b-col md="4" class="text-right">
                            ({{ moment(planTasks[0].plan.date).format('DD.MM.YYYY HH:mm') }})
                            {{ planTasks[0].plan.count }}
                            {{ planTasks[0].plan.nomenclature.measureUnit.name }}
                        </b-col>
                    </b-row>
                    <b-row class="d-none d-print-block">
                        <b-col md="8">{{ planTasks[0].plan.nomenclature.name }}</b-col>
                        <b-col md="4">
                            ({{ moment(planTasks[0].plan.date).format('DD.MM.YYYY HH:mm') }})
                            {{ planTasks[0].plan.count }}
                            {{ planTasks[0].plan.nomenclature.measureUnit.name }}
                        </b-col>
                    </b-row>
                </h3>
            </div>

            <div class="card-body">
                <task
                    v-for="task in planTasks"
                    :item="task"
                    :key="task.id"
                    @menuOpen="menuWasOpen"
                />
            </div>
        </b-card>
    </div>
</template>

<script>
import Task from './Task';
import moment from 'moment';
import StoragePicker from '@components/_common/StoragePicker';
import ShiftInput from '@components/_common/ShiftInput';

import findKey from 'lodash/findKey';
import groupBy from 'lodash/groupBy';

import Barcoder from '@utils/barcode-transition/Barcoder';
import TransitionHandler from '@utils/barcode-transition/Handler';
import {ProductionActByTaskTransition} from '@utils/barcode-transition/transitions/index';
import {PRODUCTION_TASKS_ENDPOINT} from '@utils/endpoints';

const WITH = [
    'storage',
    'operation',
    'plan.reason',
    'plan.nomenclature.measureUnit',
    'nomenclature.measureUnit',
    'stuff.nomenclature.measureUnit',
    'productionActs'
];

export default {
    components: {
        ShiftInput,
        StoragePicker,
        Task
    },
    props: {
        planId: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        date() {
            return moment(this.filters.date).format('DD.MM.YYYY')
        },
        groupedTasks() {
            return groupBy(this.tasks, 'production_plan_id')
        },
    },
    data() {
        return {
            filters: {
                storage: null,
                date: new Date,
                shift: null,
                heat: false,
            },
            tasks: [],
            dicts: {},
            opened: null
        }
    },
    methods: {
        moment,
        print() {
            window.print()
        },
        menuWasOpen(item) {
            if (this.opened == item) {
                this.opened.showCollapse = false
                this.opened = false
                return;
            } else if (this.opened) {
                this.opened.showCollapse = false
            }

            this.opened = item
            this.opened.showCollapse = true
        },
        filter() {
            let planId = this.planId;
            let storageId = this.filters.storage ? this.filters.storage.id : null
            let shiftId = this.filters.shift ? this.filters.shift.id : null
            let date = moment(this.filters.date).format('YYYY-MM-DD');
            let heat = !!this.filters.heat ? 1 : 0;

            let params = {
                storage_id: storageId,
                date: date,
                shift_id: shiftId,
                heat: heat,
                with: WITH
            };

            if (planId) {
                params.production_plan_id = planId;
            }

            localStorage.setItem('production_task_dashboard_filter', JSON.stringify(this.filters))

            if (storageId && date && shiftId) {
                this.tasks = [];
                this.$http.get(PRODUCTION_TASKS_ENDPOINT + '/list/print', {
                    params: params
                })
                    .then(response => {
                        if (this.opened) {
                            this.opened.showCollapse = false;
                            this.opened = null;
                        }

                        let tasks = response.data;

                        let barcodes = [];
                        tasks.forEach((task, key) => {
                            let params = {taskId: task.id};
                            let barcode = TransitionHandler.generate(ProductionActByTaskTransition, params);

                            barcodes.push(barcode);
                            tasks[key].barcode = barcode;
                        });

                        Barcoder.get(barcodes).then(response => {
                            let base64Barcodes = response.data;

                            base64Barcodes.forEach((record) => {
                                let taskKey = findKey(tasks, task => task.barcode === record.barcode);

                                tasks[taskKey].base64Barcode = record.content;
                            })

                            this.tasks = tasks;
                        });
                    });
            }
        },
    },
    mounted() {
        let filtersJson = localStorage.getItem('production_task_dashboard_filter')
        if (filtersJson) {
            this.filters = JSON.parse(filtersJson)
        }

        this.filter()
    },
}
</script>
<style scoped>
@page {
    size: A4;
}

.card-columns {
    column-count: 1;
}

.print {
    font-size: 35px;
    line-height: 45px;
}

.page:not(:last-child) {
    page-break-after: always;
}

.production-task-list .production-task-list-header {
    display: flex;
    padding: 0;
}

.production-task-list .production-task-list-header span {
    font-size: 18px;
}

@media (max-width: 768px) {
    .production-task-list .production-task-list-filters .card-header {
        padding: 0.75rem;
    }

    .production-task-list .production-task-list-header {
        padding: 0;
    }

    .production-task-list .production-task-list-header span {
        font-size: small;
    }
}

</style>
