<template>
    <div class="card border-dark" :class="{ 'is-not-auto-reason': isNotAutoProductionPlanReason }">
        <div class="card-header">
            {{ item.operation.name }}
            ({{ moment(item.production_start_at).format('HH:mm') }} -
            {{ moment(item.production_end_at).format('HH:mm') }})
            <template v-if="!!item.fromPreviousShift">
                <b>(c прошлой смены)</b>
            </template>
            <template v-if="!!item.fromBeforeLastShift">
                <b>(c позапрошлой смены)</b>
            </template>
        </div>
        <div class="card-body">
            <div class="card-columns">
                <div class="card ">
                    <table class="table border border-dark">
                        <tbody>
                        <tr>
                            <td v-if="!!item.nomenclature" rowspan="3">
                                {{ item.nomenclature.name }}
                                <b v-if="isNotAutoProductionPlanReason">
                                    ({{ item.plan.reason.comment.toLowerCase() }})
                                </b>
                            </td>
                            <td rowspan="3" class="text-right">{{ parseFloat(item.count).toFixed(3) }}</td>
                            <td v-if="!!item.nomenclature" rowspan="3">
                                {{ item.nomenclature.measureUnit.name }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card text-center" v-if="item.base64Barcode && item.barcode" style="border:0;margin-top:20px;">
                    <div>
                        <img :src="item.base64Barcode" style="height:45px;" alt=""/>
                    </div>
                    <div style="font-size:10px;">
                        <b>{{ item.barcode }}</b>
                    </div>
                </div>
                <div class="card">
                    <table class="table border border-dark table-sm">
                        <tbody>
                        <tr v-for="stuff in item.stuff">
                            <td>
                                {{ stuff.nomenclature.name }}
                            </td>
                            <td class="text-right">{{ parseFloat(stuff.count).toFixed(3) }}</td>
                            <td>
                                {{ stuff.nomenclature.measureUnit.name }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-12" style="font-size: smaller; margin-top: 5px;">
                {{ item.description }}
            </div>
            <div class="col-md-12" style="margin-top: 7px;" v-if="!!comment">
                <b>Комментарий:</b> <b>{{ comment }}</b>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

const AUTO_PRODUCTION_PLAN_REASON = 'auto';
const AUTO_ADDITIONAL_PRODUCTION_PLAN_REASON = 'auto_additional';

export default {
    name: "task",
    props: ['item', 'dicts', 'storage', 'shiftId'],
    computed: {
        isNotAutoProductionPlanReason() {
            let isNotAuto =
                this.item.plan.reason.name !== AUTO_PRODUCTION_PLAN_REASON &&
                this.item.plan.reason.name !== AUTO_ADDITIONAL_PRODUCTION_PLAN_REASON;

            return isNotAuto;
        },
        comment() {
            return this.item.productionActs
                .filter(productionAct => !!productionAct.comment)
                .map(productionAct => productionAct.comment)
                .join(' / ');
        }
    },
    methods: {
        moment,
    }
}
</script>

<style scoped>
.is-not-auto-reason {
    background-color: #f8d7da;
}

.table {
    width: 100% !important;
    margin-bottom: 0;
}

table.table td:nth-of-type(1) {
    width: 100%;
}

.table td, .table th {
    padding: .5rem;
}

.table tbody > tr:nth-of-type(1) td {
    border-top: none;
}

.card-columns {
    column-count: 2;
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .card-columns {
        column-count: 1;
    }
}

.selected {
    background-color: #80808085;
}

.sub-menu {
    bottom: 0;
    right: 0;
    border: 2px solid #80808085;
    background-color: #80808085;
    padding: 2px 12px;
    z-index: 5;
}

.card {
    page-break-inside: avoid;
}

.task-card.card {
    border-top: solid 3px black;
}
</style>
